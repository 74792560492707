import { useContext } from "react";
import "./App.css";
import { stateContext } from "./stateContext";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  AboutMePage,
  ExplorePage,
  HomePage,
  MorePage,
  ServicePage,
  SafarisPage,
} from "./pages";

function App() {
  const { currentMode } = useContext(stateContext);

  return (
    <div
      className={
        currentMode === "Dark"
          ? "dark text-white font-display"
          : " font-display"
      }
    >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/aboutMe" element={<AboutMePage />} />
        <Route path="/explore" element={<ExplorePage />} />
        <Route path="/more" element={<MorePage />} />
        <Route path="/safaris" element={<SafarisPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
