import { FaChalkboardTeacher, FaGraduationCap, FaHotel } from "react-icons/fa";
import { FaCow, FaPersonWalkingLuggage } from "react-icons/fa6";
import { MdCall, MdFacebook, MdMail } from "react-icons/md";
import { FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";

export const services = [
  {
    title: "Hotel Reservation",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat repudiandae iste debitis sed alias optio ad nihil saepe explicabo.",
    icon: <FaHotel className="text-4xl" />,
  },
  {
    title: "Private Guided Visit",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat repudiandae iste debitis sed alias optio ad nihil saepe explicabo, temporibus eligendi cumque ipsam aspernatur ipsa consequatur vel incidunt officia molestias.",
    main: true,
    icon: <FaPersonWalkingLuggage className="text-5xl text-orange-300" />,
  },
  {
    title: "Guide training and Development",
    content:
      "Exclusive, private safari with top-notch wildlife viewing, personalized lodging and transfers. Flexible itinerary lets you focus on preferred elite and authentic attractions",
    icon: <FaChalkboardTeacher className="text-4xl" />,
  },
  {
    title: "Hot air baloon",
    content:
      "Experience the breathtaking beauty of the African wilderness from a heavenly perspective. Joseph the Safari Guide accompanies and shares his wealth of knowledge, providing a truly personalised experience.",
    icon: <FaChalkboardTeacher className="text-4xl" />,
  },
];

export const social = [
  {
    label: "facebook",
    link: "",
    icon: <MdFacebook className="text-2xl text-blue-500" />,
  },
  {
    label: "instagram",
    link: "",
    icon: <FaInstagram className="text-2xl text-orange-300" />,
  },
  {
    label: "whatsapp",
    link: "https://wa.me/250784904806",
    icon: <FaWhatsapp className="text-2xl text-green-600" />,
  },
  {
    label: "linkedin",
    link: "",
    icon: <FaLinkedin className="text-2xl text-blue-500" />,
  },
  {
    label: "email",
    link: "mailto:neojoram12@gmail.com",
    icon: <MdMail className="text-2xl text-red-700" />,
  },
  {
    label: "phone",
    link: "tel:+250782555259",
    icon: <MdCall className="text-2xl text-green-700" />,
  },
];

export const safaris = [
  {
    title: "Mobile Camping",
    content:
      "A mobile tented camp provides access to a wider range of wild areas across Rwanda and fosters a deeper connection to our surroundings compared to a traditional lodge safari. This approach immerses us in the heart of nature, offering a private slice of wilderness. Each rewarding day on safari concludes around the campfire, with the iconic sounds of the bush enveloping us and the Milky Way overhead.",
    image: <FaHotel className="text-4xl" />,
  },
  {
    title: "Safari Lodges & Camps",
    content:
      "I partner with a selection of outstanding, responsible safari camps and lodges, strategically situated to offer iconic wildlife and cultural experiences that make a safari unforgettable. These small, intimate establishments are chosen for their emphasis on personalized service and exceptional experiences, contributing to wildlife conservation, habitat preservation, and the support of local communities.",
    image: <FaHotel className="text-4xl" />,
  },
  {
    title: "Mammalwatching, Birding, & Other Special Interests",
    content:
      "While the big five have long captivated visitors to Africa, Rwanda offers hidden gems like birds, frogs, and smaller mammals that can equally spark your imagination. Whether you’re keen on spotting the elusive Potto in Nyungwe Forest, the rare Grauer's Swamp-Warbler in the Rugezi Marsh, or the stunning butterflies in our Akagera National Park, my passion and knowledge of these creatures uniquely position me to guide such trips. \n I have extensive expertise in Rwanda’s lesser-known mammals and where to find them, and now specialize in leading mammalwatching safaris across Rwanda.",
    image: <FaCow className="text-4xl" />,
  },
  {
    title: "Conservation & Learning safaris",
    content:
      "With my passion for conservation, extensive contacts within the conservation community, and a relentless pursuit of ecological knowledge, I am uniquely positioned to facilitate hands-on educational experiences at the forefront of conservation in Rwanda. \n I can create behind-the-scenes conservation and educational safaris, organizing time spent with researchers in various natural history fields. Additionally, I run natural history courses for safari guides and nature enthusiasts seeking in-depth learning during their Rwandan safari.",
    image: <FaGraduationCap className="text-4xl" />,
  },
];
