import React, { useContext, useEffect, useRef, useState } from "react";
import { stateContext } from "../stateContext";
import ReactPlayer from "react-player";
import { Card, Modal, message } from "antd";
import axios from "axios";
import SkeletonLoader from "./skeleton";
import { Link } from "react-router-dom";

export default function Videos() {
  const { laptop, mobile, tablet, apiUrl, serverUrl } =
    useContext(stateContext);
  const { Meta } = Card;
  const [videos, setVideos] = useState([]);
  const [playerVideo, setPlayerVideo] = useState({});
  const [open, setOpen] = useState(false);
  const playerRef = useRef(null);

  const showModal = (item) => {
    setOpen(true);
    setPlayerVideo(item);
  };
  const handleCancel = () => {
    setOpen(false);
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pauseVideo();
    }
  };

  async function fetchVideos() {
    message.loading({ content: "Loading", duration: 0 });
    let config = {
      url: apiUrl + "/showVideos",
      method: "get",
    };
    const res = await axios.request(config);
    setVideos(res.data.data);
    message.destroy();
  }
  useEffect(() => {
    fetchVideos();
    // eslint-disable-next-line
  }, []);

  let skeletons = [1, 2, 3];

  return (
    <div
      className={`${
        laptop
          ? "px-20 py-14"
          : mobile
          ? "px-6"
          : tablet
          ? "px-20"
          : "px-64 py-16"
      } py-14 bg-orange-100 bg-opacity-15`}
    >
      <div className="w-full text-center font-semibold text-4xl pb-10">
        Your Packages
      </div>

      <div className={`grid ${mobile ? "gap-8" : " grid-cols-3 gap-8"}`}>
        {videos.length > 0
          ? videos.slice(0, 3).map((video, index) => (
              <Card
                key={index}
                style={{
                  padding: 20,
                  overflow: "hidden",
                  boxShadow:
                    "0 10px 15px -3px rgba(4, 120, 87, 0.4), 0 4px 6px -2px rgba(4, 120, 87, 0.4)",
                  borderRadius: "0.75rem",
                  zIndex: 0,
                }}
                cover={
                  <img alt={video.position} src={serverUrl + video.thumbnail} />
                }
                onClick={() => showModal(video)}
                size="small"
              >
                <Meta title={video.title} />
              </Card>
            ))
          : skeletons.map((index) => <SkeletonLoader key={index} />)}
      </div>

      <div className="w-full flex items-center justify-center pt-10">
        <Link
          to={"/Explore"}
          className="px-5 py-2 bg-green-800 bg-opacity-60 font-semibold rounded-full float-end hover:bg-green-700 text-white"
        >
          More Packages
        </Link>
      </div>

      <Modal
        title={"Video Player"}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={mobile ? "100%" : tablet ? "80%" : "50%"}
        style={{ height: "auto" }}
      >
        <ReactPlayer
          ref={playerRef}
          url={playerVideo.videoUrl}
          width={"100%"}
          playing
        />
      </Modal>
    </div>
  );
}
