import React, { useContext, useEffect, useState } from "react";
import { stateContext } from "../stateContext";
import axios from "axios";
import { Carousel } from "antd";
import { Navmenu } from "../layout";

export default function Banner() {
  const { mobile, tablet, apiUrl, serverUrl, active } =
    useContext(stateContext);
  const [slides, setSlides] = useState([]);

  async function fetchSlides() {
    let config = {
      url: apiUrl + "/showPictures",
      method: "get",
    };
    const res = await axios.request(config);
    setSlides(res.data.data.filter((element) => element.position === "pages"));
  }

  useEffect(() => {
    fetchSlides();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`relative overflow-hidden top-0`}>
      <Navmenu />

      <div className="absolute top-0 right-0 -z-50 w-full">
        <Carousel autoplay autoplaySpeed={10000}>
          {slides.map((slide, index) => (
            <img
              key={index}
              src={serverUrl + slide.imgUrl}
              alt={slide.title}
              className="w-fill"
            />
          ))}
        </Carousel>
      </div>

      <div
        className={`${
          mobile
            ? "w-full h-[25vh] px-5"
            : tablet
            ? "w-full h-[25vh] px-5"
            : "w-full h-[35vh]"
        } flex flex-col items-center justify-center bg-gradient-to-br from-black/30 to-black/20 bg-cover`}
      >
        <h3
          className={`names ${
            mobile ? "text-3xl" : "text-4xl"
          } text-white font-extrabold capitalize`}
        >
          {active.includes("services")
            ? "Our Services"
            : active.includes("explore")
            ? "Our Packages"
            : active.includes("safaris")
            ? "Plan Your Safari"
            : "Welcome back"}
        </h3>
        <span
          className={`rounded-md text-white px-2 py-2 text-center ${
            mobile ? "w-fit text-justify line-clamp-3 leading-8" : " w-2/3"
          }`}
        >
          The most memorable safaris are custom-made by clients and guides to
          highlight Africa’s finest, cater to personal preferences, and
          accommodate your family’s needs. Standard itineraries fall short, so I
          invite you to contact us to discuss planning your next safari.
        </span>
      </div>
    </div>
  );
}
