import React, { useContext, useState } from "react";
import { stateContext } from "../stateContext";
import { Button, Input, message } from "antd";
import { social } from "./jsonLists";
import axios from "axios";

const { TextArea } = Input;
export default function Contact() {
  const { laptop, mobile, tablet, apiUrl } = useContext(stateContext);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const handleOk = async () => {
    setConfirmLoading(true);
    message.loading({ content: "Storing feedback", duration: 0 });
    try {
      let config = {
        method: "post",
        url: apiUrl + "/addComment",
        data: {
          email,
          subject,
          content,
          priority:
            content.length < 50
              ? "Low"
              : content.length < 100
              ? "Medium"
              : "High",
        },
      };

      await axios.request(config);
      message.destroy();
      message.success("Operation successful");

      setTimeout(() => {
        setConfirmLoading(false);
      }, 2000);
    } catch (error) {
      message.error({ content: "Operation failed" });
    }
  };

  return (
    <div
      id="contact"
      className={`${
        laptop ? "px-40" : mobile ? "px-4" : tablet ? "px-20" : "px-80"
      }  py-14 bg-orange-200 bg-opacity-10`}
    >
      <div className="w-full text-center font-semibold text-4xl pb-10">
        Contact Me
      </div>
      <p className="text-center pb-6">
        From enjoying views of the superb snow-capped Kilimandjaro and bush
        meals in the iconic Maasai Mara to exploring Africa’s tropical paradise
        and snorkelling in the Indian Ocean, together we will design a trip of a
        lifetime.
      </p>

      <div
        className={`grid ${
          mobile ? "gap-4 text-center" : "grid-cols-2 gap-28"
        }  items-center`}
      >
        <div className="flex flex-col gap-6">
          <h3
            className={`${
              mobile ? "text-center" : "text-3xl px-4"
            } underline font-semibold text-gray-700`}
          >
            Get in touch
          </h3>
          <p>
            Have a question about your safari and how best we can design it
            together? Let’s talk, drop us an email, call us, or fill the form.{" "}
          </p>
          {mobile ? null : (
            <>
              <div className="flex items-center gap-3">
                {social.map((social, index) => (
                  <a
                    href={social.link}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                    className="hover:animate-pulse hover:border-orange-400 p-2 border-2 rounded-full"
                  >
                    {social.icon}
                  </a>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col gap-4">
          <span
            className={`${
              mobile ? "underline" : " text-xl"
            } capitalize font-semibold text-gray-700`}
          >
            Send Me a message
          </span>
          <form onSubmit={handleOk} className="flex flex-col gap-2" id="form">
            <Input
              size="large"
              placeholder="Email..."
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              size="large"
              placeholder="Subject..."
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextArea
              rows={4}
              placeholder="Your message..."
              autoSize={{ minRows: 4, maxRows: 6 }}
              onChange={(e) => setContent(e.target.value)}
            />
            <Button loading={confirmLoading} onClick={handleOk}>
              Send message
            </Button>
          </form>
        </div>

        {!mobile ? null : (
          <>
            <div
              className={`${
                mobile && "w-full flex items-center justify-center mt-2"
              } flex items-center gap-3`}
            >
              {social.map((social, index) => (
                <a
                  href={social.link}
                  key={index}
                  className="hover:animate-pulse hover:border-orange-400 p-2 border-2 rounded-full"
                >
                  {social.icon}
                </a>
              ))}
            </div>
            {/* <div className="grid">
              <span>
                Tel:{" "}
                <a href="tel:+250782555259" className="text-blue-600">
                  +250 782 555 259
                </a>
              </span>
              <span>
                Email:{" "}
                <a href="mailto:neojoram12@gmail.com" className="text-blue-600">
                  neojoram12@gmail.com
                </a>
              </span>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
}
