import React, { useContext, useEffect, useRef, useState } from "react";
import { Footer } from "../layout";
import { Banner, SkeletonLoader } from "../components";
import { FaVideo } from "react-icons/fa";
import { stateContext } from "../stateContext";
import axios from "axios";
import { Card, Modal, message } from "antd";
import ReactPlayer from "react-player";

export default function ExplorePage() {
  const { mobile, tablet, laptop, apiUrl, serverUrl } =
    useContext(stateContext);

  const { Meta } = Card;
  let skeletons = [1, 2, 3];
  const [videos, setVideos] = useState([]);
  const [playerVideo, setPlayerVideo] = useState({});
  const [open, setOpen] = useState(false);
  const playerRef = useRef(null);

  const showModal = (item) => {
    setOpen(true);
    setPlayerVideo(item);
  };
  const handleCancel = () => {
    setOpen(false);
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pauseVideo();
    }
  };

  async function fetchVideos() {
    message.loading({ content: "Loading", duration: 0 });
    let config = {
      url: apiUrl + "/showVideos",
      method: "get",
    };
    try {
      const res = await axios.request(config);

      // Modify the video URLs to use the nocookie domain
      const modifiedVideos = res.data.data.map((video) => ({
        ...video,
        videoUrl:
          video.videoUrl.includes("youtube.com") ||
          video.videoUrl.includes("youtu.be")
            ? video.videoUrl.replace("youtube.com", "youtube-nocookie.com")
            : video.videoUrl,
      }));

      setVideos(modifiedVideos);
      message.destroy();
    } catch (error) {
      message.destroy();
      console.error("Failed to load videos:", error);
    }
  }
  useEffect(() => {
    fetchVideos();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Banner />

      <div
        className={`${
          laptop ? "px-20" : mobile ? "px-4" : tablet ? "px-20" : " px-40"
        } py-4 min-h-[700px]`}
      >
        <div className={`flex items-center gap-2`}>
          <FaVideo />
          <span className="text-gray-700 text-sm">Packages</span>
        </div>
        <div className="min-h-96">
          <div
            className={`grid ${
              mobile
                ? "gap-7 mt-5"
                : laptop
                ? "grid-cols-3 gap-4 mt-10"
                : tablet
                ? "grid-cols-2"
                : "grid-cols-3 gap-10 mt-10 px-20"
            }`}
          >
            {videos.length > 0
              ? videos.map((video, index) => (
                  <Card
                    key={index}
                    style={{
                      padding: 20,
                      overflow: "hidden",
                      boxShadow:
                        "0 10px 15px -3px rgba(4, 120, 87, 0.4), 0 4px 6px -2px rgba(4, 120, 87, 0.4)",
                      borderRadius: "0.75rem",
                    }}
                    cover={
                      <img
                        alt={video.position}
                        src={serverUrl + video.thumbnail}
                      />
                    }
                    onClick={() => showModal(video)}
                    size="small"
                  >
                    <Meta title={video.title} />
                  </Card>
                ))
              : skeletons.map((index) => <SkeletonLoader key={index} />)}
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        title={"Video Player"}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={mobile ? "100%" : tablet ? "80%" : "50%"}
        style={{ height: "auto" }}
      >
        <ReactPlayer
          ref={playerRef}
          url={playerVideo.videoUrl}
          width={"100%"}
          playing
        />
      </Modal>
    </>
  );
}
