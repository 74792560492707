import React, { useContext, useEffect, useState } from "react";
import { MdPhone } from "react-icons/md";
import { stateContext } from "../stateContext";
import { Carousel } from "antd";
import axios from "axios";

export default function Profile() {
  const { mobile, tablet, apiUrl, serverUrl } = useContext(stateContext);
  const [slides, setSlides] = useState([]);

  async function fetchSlides() {
    let config = {
      url: apiUrl + "/showPictures",
      method: "get",
    };
    const res = await axios.request(config);
    setSlides(res.data.data.filter((element) => element.position === "header"));
  }

  useEffect(() => {
    fetchSlides();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${
        mobile
          ? "flex flex-col justify-center px-5 h-[30vh]"
          : tablet
          ? "flex justify-between h-[30vh] px-20"
          : "flex justify-between h-[85vh] px-40"
      } bg-gradient-to-br from-black/30 to-black/20 bg-cover`}
    >
      <div className="absolute top-0 right-0 -z-50 w-full">
        <Carousel autoplay autoplaySpeed={10000}>
          {slides.map((slide, index) => (
            <img
              key={index}
              src={serverUrl + slide.imgUrl}
              alt={slide.title}
              className={`${mobile ? "h-[100%]" : "w-fit"}`}
            />
          ))}
        </Carousel>
      </div>

      <div
        className={`${
          mobile
            ? "w-full gap-2"
            : tablet
            ? "w-full gap-6"
            : "w-full px-12 gap-8"
        } flex flex-col items-center justify-center`}
      >
        <h3
          className={`names ${
            mobile ? "text-2xl" : "text-5xl"
          } text-white font-extrabold capitalize`}
        >
          The Privately Guided Safaris
        </h3>
        <span
          className={`rounded-md text-white text-center ${
            mobile ? "line-clamp-2 leading-8 text-justify" : " px-2 w-fit"
          } `}
        >
          The most memorable safaris are custom-made by clients and guides to
          highlight Africa’s finest, cater to personal preferences, and
          accommodate your family’s needs. Standard itineraries fall short, so I
          invite you to contact us to discuss planning your next safari.
        </span>

        <a
          href="#contact"
          className="flex items-center gap-1 bg-[whitesmoke] rounded-full px-4 leading-10 cursor-pointer hover:border-orange-400 hover:animate-pulse"
        >
          <MdPhone className="text-xl" /> Contact Me
        </a>
      </div>
    </div>
  );
}
