import React, { useContext, useEffect, useState } from "react";
import { stateContext } from "../stateContext";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Story() {
  const { mobile, laptop, tablet, apiUrl, serverUrl } =
    useContext(stateContext);
  const [slides, setSlides] = useState([]);

  async function fetchSlides() {
    let config = {
      url: apiUrl + "/showPictures",
      method: "get",
    };
    const res = await axios.request(config);
    setSlides(
      res.data.data.filter((element) => element.position === "profile")
    );
  }

  useEffect(() => {
    fetchSlides();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${
        laptop
          ? "px-40 py-14"
          : mobile
          ? "px-4"
          : tablet
          ? "px-20"
          : "px-64 py-24"
      } py-14 bg-green-700 bg-opacity-20`}
    >
      {(mobile || tablet) && (
        <div className="w-full text-center font-semibold text-4xl pb-6">
          About me
        </div>
      )}
      <div
        className={`${
          mobile
            ? "flex flex-col gap-2 text-justify"
            : "flex items-start gap-10"
        }`}
      >
        <img
          src={serverUrl + slides[0]?.imgUrl}
          alt={"me"}
          className={`${mobile ? "px-5" : "w-1/2"} rounded-lg`}
        />
        <div className="w-full">
          {!mobile && !tablet && (
            <div className="w-full text-center font-semibold text-4xl pb-10">
              About me
            </div>
          )}
          <div className="text-justify">
            <p className="font-semibold">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad
              possimus ea iusto consequuntur quos qui quaerat provident facilis!
              Modi reiciendis neque corrupti quae nihil explicabo numquam porro
              inventore, reprehenderit fugiat?
            </p>
            <p className="my-4">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos ex
              quaerat, corporis corrupti voluptatum fugit soluta aspernatur
              tempora reiciendis temporibus sit optio nemo maiores, libero
              ducimus non veritatis molestias explicabo!
            </p>
          </div>
          <div className="p-4 w-full flex items-center justify-center">
            <Link
              to={"/aboutMe"}
              className={`px-5 py-2 bg-orange-300 rounded-full hover:border hover:bg-orange-400 hover:text-white`}
            >
              Continue Reading
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
