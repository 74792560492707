import React, { useContext } from "react";
import { Footer } from "../layout";
import { Banner, SkeletonLoader } from "../components";
import { FaServicestack } from "react-icons/fa";
import { services } from "../components/jsonLists";
import { stateContext } from "../stateContext";

export default function ServicesPage() {
  const { mobile, laptop, tablet } = useContext(stateContext);
  let skeletons = [1, 2, 3, 5, 6, 7];

  return (
    <>
      <Banner />
      <div
        className={`${
          laptop ? "px-20" : mobile ? "px-4" : tablet ? "px-20" : " px-40"
        } py-4 min-h-[700px]`}
      >
        <div className={`flex items-baseline gap-2`}>
          <FaServicestack />
          <span className="text-gray-700 text-sm">Services</span>
        </div>

        <div className="min-h-96">
          <div
            className={`grid ${
              mobile
                ? "gap-7 mt-5"
                : laptop
                ? "grid-cols-3 gap-4 mt-10"
                : tablet
                ? "grid-cols-2"
                : "grid-cols-3 gap-10 mt-10 px-20"
            }`}
          >
            {services.length > 0
              ? services.map((service, index) => (
                  <div key={index} className={`py-10 px-6 rounded-xl shadow-lg shadow-green-700/40 hover:animate-skeleton-loading`}>
                    <div className={`w-full flex items-center justify-center`}>
                      {service.icon}
                    </div>
                    <div
                      className={`text-center w-full font-semibold text-2xl`}
                    >
                      {service.title}
                    </div>
                    <p className={`pt-6 text-justify`}>{service.content}</p>
                  </div>
                ))
              : skeletons.map((index) => <SkeletonLoader key={index} />)}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
