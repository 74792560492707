import React from "react";

export default function SkeletonLoader() {
  return (
    <div className="p-5 shadow-lg shadow-black/20 rounded-xl">
      <div className="w-full">
        <div className="w-full h-48 rounded-md mb-2 animate-skeleton-loading"></div>
      </div>

      <div className="w-full">
        <div className="w-full h-4 rounded-md mb-2 animate-skeleton-loading"></div>
      </div>

      <div className="w-1/3 h-3 text-center rounded-md mb-2 animate-skeleton-loading"></div>
    </div>
  );
}
