import React, { useContext, useEffect, useState } from "react";
import { Footer, Navmenu } from "../layout";
import { stateContext } from "../stateContext";
import axios from "axios";
import { Contact } from "../components";

export default function AboutMePage() {
  const { mobile, laptop, tablet, apiUrl, serverUrl } =
    useContext(stateContext);
  const [slides, setSlides] = useState([]);

  async function fetchSlides() {
    let config = {
      url: apiUrl + "/showPictures",
      method: "get",
    };
    const res = await axios.request(config);
    setSlides(
      res.data.data.filter((element) => element.position === "profile")
    );
  }

  useEffect(() => {
    fetchSlides();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navmenu />

      <div
        className={`${
          laptop ? "px-32" : mobile ? "px-4" : tablet ? "px-20" : " px-52"
        } pt-10 pb-14 min-h-[500px]`}
      >
        <div
          className={`${
            (mobile || tablet) && "flex flex-col gap-2 text-justify"
          } flex items-start gap-8`}
        >
          {(mobile || tablet) && (
            <div className="w-full text-center font-semibold text-4xl p-2">
              About Me
            </div>
          )}
          <div className={`${(mobile || tablet) && "w-full"} w-1/2`}>
            <img
              src={serverUrl + slides[0]?.imgUrl}
              alt={slides[0]?.title}
              className={`${mobile && ""} w-full rounded-lg`}
            />
          </div>
          <div
            className={`flex flex-col gap-2 text-justify justify-end ${
              (mobile || tablet) && "w-full"
            } w-1/2`}
          >
            {!mobile && !tablet && (
              <div className="w-full text-center font-semibold text-4xl pb-6">
                About Me
              </div>
            )}
            <p className="font-semibold leading-8">
              Daniël lives near the park, enjoying the wildlife. He did
              education in the first place. He applied to an advert from the
              park. He started working there in 2013 and moved to tourist
              guiding in 2014. He learned by doing; he got his training from the
              park. He worked for the park for 9 years and the last 2 years as a
              freelance guide. He is also the CEO and Co-founder of Akagera
              Community Freelance Guide.
            </p>
            <p className="leading-8">
              Daniël's knowledge in birdwatching is unparalleled. During a
              training session, he spotted a rare, minuscule bird, which he
              proudly added to his list of sightings. The trainer, initially
              skeptical, was proven wrong when Daniël was the only one to
              correctly answer a question about this very bird during an exam.
              This incident solidified his reputation as 'the birder' of Akagera
              Park, and he has since shared his knowledge with numerous guides.
            </p>
            <p className="leading-8">
              <i className="font-semibold">
                Apart from the birds, his favorite animals in the park are the
                big five.
              </i>
              <br />
              Daniël's love for tourism extends beyond the thrill of
              exploration. It is a platform for him to educate others on the
              importance of preserving nature and wildlife for future
              generations. A tour with Daniël is not just a wildlife adventure,
              but also a cultural immersion, offering a glimpse into the rich
              traditions of Rwanda.
            </p>
          </div>
        </div>
      </div>

      <Contact />
      <Footer />
    </>
  );
}
