import React, { useContext } from "react";
import { Footer } from "../layout";
import { Banner, Contact, SkeletonLoader } from "../components";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { safaris } from "../components/jsonLists";
import { stateContext } from "../stateContext";

export default function SafarisPage() {
  const { mobile, laptop, tablet } = useContext(stateContext);
  let skeletons = [1, 2, 3, 5, 6, 7];

  return (
    <>
      <Banner />
      <div
        className={`${
          laptop ? "px-20" : mobile ? "px-4" : tablet ? "px-20" : " px-40"
        } pt-10 pb-20 min-h-[700px]`}
      >
        <div className={`flex items-baseline gap-2`}>
          <FaPersonWalkingLuggage />
          <span className="text-gray-700 text-sm">Safaris</span>
        </div>

        <div className="min-h-96">
          <div
            className={`grid ${
              mobile
                ? "gap-7 mt-5"
                : laptop
                ? "gap-4 mt-10"
                : tablet
                ? ""
                : "gap-10 mt-10 px-32"
            }`}
          >
            {safaris.length > 0
              ? safaris.map((safari, index) => (
                  <div
                    key={index}
                    className={`${
                      mobile ? "px-4" : "flex items-center justify-between px-16"
                    } gap-6 py-10 rounded-xl shadow-lg shadow-green-700/40 hover:animate-skeleton-loading`}
                  >
                    <div
                      className={`${
                        mobile ? "w-fit" : " w-1/2"
                      } flex items-center justify-center`}
                    >
                      {safari.image}
                    </div>
                    <div className={`${mobile ? "w-fit" : " w-1/2"} leading-8`}>
                      <span
                        className={`text-center w-full font-semibold text-2xl`}
                      >
                        {safari.title}
                      </span>
                      <p className={`pt-6 text-justify`}>{safari.content}</p>
                    </div>
                  </div>
                ))
              : skeletons.map((index) => <SkeletonLoader key={index} />)}
          </div>
        </div>
      </div>

      <Contact />
      <Footer />
    </>
  );
}
