import React, { useContext } from "react";
import { Profile, Navmenu } from ".";
import { stateContext } from "../stateContext";

export default function Header() {
  const { mobile } = useContext(stateContext);

  return (
    <div className={`relative  ${mobile ? "" : "overflow-hidden"} `}>
      <Navmenu />
      <Profile />
    </div>
  );
}
