import React from "react";
import { Container, Footer, Header } from "../layout";

export default function HomePage() {
  return (
    <>
      <Header />
      <Container />
      <Footer />
    </>
  );
}
