import React, { useContext, useEffect, useState } from "react";
import { MdBook, MdExplore, MdHomeMax, MdPhone } from "react-icons/md";
import { AiFillApi } from "react-icons/ai";
import { FaBars, FaTimes } from "react-icons/fa";
import { stateContext } from "../stateContext";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import { social } from "../components/jsonLists";

export default function Navmenu() {
  const { mobile, tablet, showMenu, active } = useContext(stateContext);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [current, setCurrent] = useState(0);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [previousScrollY, setPreviousScrollY] = useState(0);

  const items = [
    {
      label: "Home",
      key: "home",
      icon: <MdHomeMax className="text-xl" />,
    },
    {
      label: "Services",
      key: "services",
      icon: <AiFillApi className="text-xl" />,
    },
    {
      label: "Safaris",
      key: "safaris",
      icon: <AiFillApi className="text-xl" />,
    },
    {
      label: "Explore",
      icon: <MdExplore className="text-xl" />,
      key: "explore",
    },
    { label: "About_Me", key: "aboutMe", icon: <MdBook className="text-xl" /> },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Check if the scroll position is not exactly 20 pixels
      const isNavbarFixed = scrollY >= 5;
      setIsNavbarFixed(isNavbarFixed);

      setPreviousScrollY(scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [previousScrollY]);

  return (
    <>
      {mobile ? (
        <div className="flex items-center justify-between py-4 px-5">
          <div className="flex flex-col">
            <span
              className={`${
                tablet || mobile ? "text-lg" : "text-2xl"
              }  font-semibold uppercase`}
            >
              DANIEL THE SAFARI GUIDE
            </span>
            <span className="text-sm">Exclusive | Tailored | Responsive</span>
          </div>
          {showMenu ? (
            <FaTimes className="text-2xl" onClick={showDrawer} />
          ) : (
            <FaBars className="text-2xl" onClick={showDrawer} />
          )}
          <Drawer
            title="Menu"
            placement={"left"}
            closable={true}
            onClose={onClose}
            open={open}
            key={"right"}
            width={"75%"}
          >
            <div className="flex flex-col gap-4">
              {items.map((menu, index) => (
                <Link
                  to={"/" + menu.key}
                  key={index}
                  className={`flex gap-2 ${
                    (active.includes(menu.key) ||
                      (active === "/" && index === 0)) &&
                    "text-orange-500"
                  }`}
                >
                  {menu.icon}
                  {menu.label}
                </Link>
              ))}
            </div>
            <div className="underline italic my-4">Social links</div>

            <div className="flex flex-col gap-4">
              {social.map((social, index) => (
                <Link
                  to={social.link}
                  target="_blank"
                  key={index}
                  className="flex gap-2"
                >
                  {social.icon} {social.label}
                </Link>
              ))}
            </div>
          </Drawer>
        </div>
      ) : (
        <div
          className={`shadow flex items-center justify-between py-6 px-20 ${
            isNavbarFixed ? "fixed w-full bg-green-800/40" : ""
          } z-50`}
        >
          <div className="flex flex-col">
            <span
              className={`${
                tablet || mobile ? "text-lg" : "text-2xl"
              }  font-semibold uppercase`}
            >
              DANIEL THE SAFARI GUIDE
            </span>
            <span>Exclusive | Tailored | Responsive</span>
          </div>
          <ul className="flex items-center gap-2">
            {items.map((menu, index) => (
              <li
                key={index}
                onMouseEnter={() => setCurrent(index)}
                onMouseLeave={() => setCurrent(null)}
                className={` list-none leading-10`}
              >
                <Link
                  to={"/" + menu.key}
                  className={`${
                    mobile || tablet ? "px-1" : "px-3"
                  } flex items-center gap-1 hover:text-orange-700 ${
                    (active.includes(menu.key) ||
                      (active === "/" && index === 0)) &&
                    "text-orange-500 border-b-[1px]"
                  }`}
                >
                  {menu.label}
                </Link>

                {menu.children && current === index && (
                  <ul
                    className={`absolute shadow ${
                      isNavbarFixed
                        ? "bg-green-800 bg-opacity-90"
                        : "bg-green-100/50"
                    }`}
                  >
                    {menu.children.map((sub, idx) => (
                      <li key={idx} className="list-none hover:text-orange-700">
                        <Link
                          to={"/" + menu.key}
                          className={`min-w-28 px-3 pt-1 flex items-center gap-1 ${
                            active.includes(sub.key) &&
                            "text-orange-500 border-b-[1px]"
                          }`}
                        >
                          {sub.icon} {sub.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>

          <a
            href="#contact"
            className="flex items-center gap-1 border rounded-full px-4 leading-8 cursor-pointer hover:border-orange-400 hover:animate-pulse"
          >
            <MdPhone className="text-xl" /> Contact Me
          </a>
          <div className="fixed top-1/3 right-14">
            <div className="flex flex-col items-center gap-3">
              <div className="w-[1px] h-6 bg-black"></div>
              {social.map((social, index) => (
                <a
                  href={social.link}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  className="hover:animate-pulse hover:border-orange-400 p-2 border-2 rounded-full text-black"
                >
                  {social.icon}
                </a>
              ))}
              <div className="w-[1px] h-6 bg-black"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
