import React, { useContext } from "react";
import { stateContext } from "../stateContext";
import { Link } from "react-router-dom";
import { services } from "./jsonLists";

export default function Services() {
  const { laptop, mobile, tablet } = useContext(stateContext);

  return (
    <div
      className={`${
        laptop ? "px-24" : mobile ? "px-4" : tablet ? "px-20" : "px-60"
      } py-14 bg-green-800 bg-opacity-25`}
    >
      <div className="w-full text-center font-semibold text-4xl pb-4">
        Services
      </div>
      <p className="text-center pb-4 italic">
        Join me as we explore some of Africa’s unique wildlife and breathtaking
        landscapes. Together we will go on a journey full of wonders, an
        adventure you would wish to never end.
      </p>

      <div
        className={`flex gap-10 ${
          mobile ? "flex-col" : tablet ? "gap-6" : "flex items-end"
        }`}
      >
        {services.slice(0, 3).map((service, index) => (
          <div
            key={index}
            className={`${service.main && "bg-green-800 bg-opacity-60"} ${
              mobile ? "" : " w-1/3"
            } py-10 px-5 rounded-lg shadow-2xl`}
          >
            <div className={` w-full flex items-center justify-center`}>
              {service.icon}
            </div>
            <div
              className={`${
                service.main && "text-white"
              } text-center w-full font-semibold text-2xl`}
            >
              {service.title}
            </div>
            <p
              className={`pt-6 text-center ${
                service.main ? "text-white line-clamp-4" : "line-clamp-2"
              }`}
            >
              {service.content}
            </p>
          </div>
        ))}
      </div>

      <div className="w-full flex items-center justify-center pt-10">
        <Link
          to={"/services"}
          className="px-5 py-2 bg-green-800 bg-opacity-60 font-semibold rounded-full float-end hover:bg-green-700 text-white"
        >
          More Services
        </Link>
      </div>
    </div>
  );
}
